import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  host: { class: "dialogComponent" }
})
export class DialogConfirmComponent implements OnInit {
  message: string;
  okTitle: string;
  okColor = "primary";
  cancelTitle: string;
  cancelColor = "warn";

  constructor(private dialogRef: MatDialogRef<DialogConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.okTitle = data.okTitle || "Ok";
    this.cancelTitle = data.cancelTitle || $localize`Avbryt`;

    if (data.invert) {
      this.okColor = "warn";
      this.cancelColor = "primary";
    }
  }

  ngOnInit() {
  }

  close(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }
}
