export class FacebookConnectRequest {
    id: string;
    email: string;
    name: string;
    imageUrl: string;
    accessToken: string;
}

export class FacebookConnectResponse {
    success: boolean;
    alreadyConnected: boolean;
    exists: boolean;
    grantedScopes: string[];
    userId: number;
}