import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-google-login-dialog',
  templateUrl: './google-login-dialog.component.html',
  styleUrls: ['./google-login-dialog.component.scss']
})
export class GoogleLoginDialogComponent implements OnInit {
  scope: string;

  constructor(private dialogRef: MatDialogRef<GoogleLoginDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.scope = data.scope;
  }

  ngOnInit(): void {
  }

  connect() {
    // @ts-ignore
    const client = google.accounts.oauth2.initCodeClient({
      client_id: environment.google.clientId,
      scope: 'https://www.googleapis.com/auth/business.manage',
      ux_mode: 'popup',
      callback: (response) => {
        this.dialogRef.close(response)
      },
    });
    client.requestCode();
    /*
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.grantOfflineAccess({ prompt: 'consent', scope: this.scope }).then(
      response => this.dialogRef.close(response),
      err => this.dialogRef.close()
    );
    */
  }
}
