// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  beta: false,
  backend: {
    url: 'https://localhost:44375',
  },
  eventsapp: {
    url: 'https://eventapp-dev.basetool.com',
    backend: 'https://eventapp-backend-dev.basetool.com'
  },
  media: {
    contentEndpoint: 'https://mediacontent-dev.basetool.com',
    publicEndpoint: 'https://mediaorigin-dev.basetool.com'
  },
  facebook: {
    appId: '3082309125328939',
    apiVersion: 'v9.0'
  },
  google: {
    clientId: '669776535801-sh09bsmrolqcsj3vvcurcd73cnqtst8v.apps.googleusercontent.com',
    key: 'AIzaSyBXDKnrtoNcuYGGdmJkTEu_w8QT_g0OJnw',
    reCaptcha: '6LfKVLIZAAAAAIN79e7KM_5TUxkGi-A2H02NNJ1P',
    reCaptchav2: '6LfQe7IZAAAAAKNqqJIO_MzSrYoUdx0lMnopdih1',
    analyticsId: 'UA-72253134-2'
  },
  adyen: {
    originKey: 'pub.v2.8015694165933430.aHR0cHM6Ly9sb2NhbGhvc3Q6NDIwMA.PHVfr2yy4lq52Bjkz2CIv2NmCtK9oJPf4qqTY83OEmI',
    scriptVersion: '3.5.0'
  },
  notifications: {
    applicationServerPublicKey: 'BD5A0S-miJrEI3oc2DTqtWXT90_8lmAXQpjPUmIOJmbJLKXliJ0sYHFwYvgOOFUe_WEF1yPaK7HN8AC-aXrPAPU'
  },
  netseasy: {
    checkoutKey: 'e0b8606fbd1f4508864b2c83104c9550',
    environment: 'test'
  },
  breakpoints: {
    small: '(max-width:599px)',
    medium: '(max-width:959px)',
    large: '(max-width:1279px)',
    xlarge: '(max-width:1920px)'
  },
  booking: {
    basetoolBookingSystemId: 9,
    channelId: 1018
  },
  tinyMce: {
    defaultSettings: {
      base_url: '/assets/tinymce',
      language: 'sv_SE',
      suffix: '.min',
      content_css: '/assets/tinymce/skins/content/custom/content.css',
      inline: false,
      height: '100%',
      menubar: false,
      statusbar: false,
      plugins: 'link lists paste, paste',
      toolbar: 'styleselect | bold italic | bullist numlist | link unlink | undo | paste',
      paste_as_text: true,
      style_formats: [
        { title: 'Ingress', inline: 'span', classes: 'preamble' },
        { title: 'Mellanrubrik', format: 'h3' }
      ]
    }
  },
  myDatePicker: {
    styles: `
    .ng-mydp * {
      font-family: inherit;
    }

    .monthYearSelBar {
      display: flex;
      background: none;
      height: auto;
      justify-content: space-between;
      align-items: center;
      height: auto;
      padding: .5rem 0;
      border-bottom: solid 1px #eaedf3;
      border-top: solid 1px #eaedf3;
    }

    .myDpIcon {
      font-family: 'Material Icons';
      color: inherit;
      font-weight: normal;
      font-style: normal;
      font-size: 32px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
    }

    .myDpIconLeftArrow:before {
      content: "chevron_left";
    }

    .myDpIconRightArrow:before {
      content: "chevron_right";
    }

    .myDpCalTable {
      border-collapse: collapse;
      height: auto;
    }

    .myDpSelector, .myDpSelector:focus {
      border: none
    }

    .myDpHeaderBtn {
      background: none;
    }

    .myDpPrevBtn, .myDpNextBtn {
      margin-left: 0;
      margin-right: 0;
      width: 32px;
    }

    .myDpPrevBtn {
      margin-left: 1rem;
    }

    .myDpMonthYearText {
      width: auto;
      margin: 0 auto;
    }

    .myDpNextBtn {
      margin-right: 1rem;
    }

    .myDpCalTable td.myDpDaycellWeekNbr, .myDpCalTable th.myDpWeekDayTitleWeekNbr {
      border: solid 1px #eaedf3;
    }

    .ng-mydp table th {
      padding: 1rem;
    }

    th.myDpWeekDayTitleWeekNbr {
      visibility: hidden;
    }

    .myDpWeekDayTitle {
      background: none;
      border-bottom: solid 1px #eaedf3;
      font-size: 1.4rem;
    }

    .myDpCurrMonth {
      background: #eaedf3;
    }

    .myDpMarkCurrDay, .myDpMarkCurrMonth, .myDpMarkCurrYear {
      border: none;
    }

    .myDpHighlight {
      color: inherit;
    }

    .myDpMonthLabel, .myDpYearLabel {
      background: none;
      font-size: 1.8rem;
      font-weight: 500;
    }

    .myDpDaycell, .myDpMonthcell, .myDpYearcell {
      border: solid 1px #EAEDF3 !important;
      background: #FBFBFD;
    }

    .myDpDaycell.myDpNextMonth, .myDpDaycell.myDpNextMonth.myDpSelectedDay, .myDpDaycell.myDpDisabled, .myDpDaycell.myDpDisabled  {
      color: #9EA0A5;
      background: #fff;
    }

    .myDpMonthcell, .myDpYearcell {
      padding: 2rem 0 !important;
    }

    .myDpDaycell, .myDpDayValue {
      position: relative;
    }

    .myDpDaycell:before {
        content: "";
        float: left;
        padding-bottom: 100%;
    }

    .myDpDaycell:after {
        content: "";
        display: table;
        clear: both;
    }

    .myDpDayValue {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .myDpMarkDate {
      background: #2258d2;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 0;
    }

    .myDpMarkDate + .myDpDayValue {
      color: #fff;
    }

    .myDpSelectedDay, .myDpSelectedMonth, .myDpSelectedYear {
      background: #FBFBFD;
      border-radius: 0;
    }`
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
