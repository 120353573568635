import { ErrorHandler, Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor() {}

  //Handle errors that's not been catched/handled
  handleError(error: any) {
    const innerError = error.rejection || error;

    if (innerError && innerError instanceof HttpErrorResponse) {        
        if (innerError.status == 401) {
          //We don't have access to the route here so we store the location in ../interceptors/base.interceptor.ts
          var returnUrl = sessionStorage.getItem('authReturnUrl');
          window.location.href = '/auth/login' + (!!returnUrl ? '?return=' + encodeURIComponent(returnUrl) : '');
          return;
        }
        else if (innerError.status == 403) {
          window.location.href = '/error/403';
          return;
        }
        else if (innerError.status == 500) {
          if (environment.production) {
            window.location.href = '/error/500';
          }
          return;
        }
    }
    else if (!!innerError.name && innerError.name === 'ChunkLoadError') { //Indicates that angular failed to lazy load script
      window.location.reload();
      return;
    }

    console.error('UNCAUGHT ERROR', error);
  }
}