import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: ''
})
export class RootComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.http.get('/auth/isauthorized')
    .subscribe(() => {
      this.router.navigate(['/admin']);
    },
    error => {
      this.router.navigate(['/auth', 'login']);
    });
  }

}
