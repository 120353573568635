import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';

declare var document: any;

@Injectable({
    providedIn: 'root'
})
export class ScriptService {

    private scripts: any = {};
    private css: any = {};

    constructor() {
        this.scripts = {
            'facebookapi': { src: 'https://connect.facebook.net/{0}/sdk.js', attrs: { crossorigin: 'anonymous' } },
            'googleapi': { src: 'https://apis.google.com/js/platform.js?onload={0}' },
            'googleaccounts': { src: 'https://accounts.google.com/gsi/client'},
            'googlemaps': { src: 'https://maps.googleapis.com/maps/api/js?key={0}&libraries=places&callback={1}' },
            'gtag': { src: 'https://www.googletagmanager.com/gtag/js?id={0}' },
            'recaptcha': { src: 'https://www.google.com/recaptcha/api.js?render={0}&onload={1}' },
            'adyen': { src: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/' + environment.adyen.scriptVersion + '/adyen.js' },
            'netseasy_test': { src: 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1' },
            'netseasy_live': { src: 'https://checkout.dibspayment.eu/v1/checkout.js?v=1' }
        };

        this.css = {
            'adyen': { src: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/' + environment.adyen.scriptVersion + '/adyen.css' },
            'googlemaps': { src: 'https://maps.googleapis.com/maps/api/js?key={0}&callback={1}&libraries=places' }
        };
    }

    loadScript(name: string, params?: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            //resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                //load script
                let src = this.scripts[name].src;
                if (params) {
                    for (let i = 0; i < params.length; i++) {
                        src = src.replace('{' + i + '}', params[i]);
                    }
                }

                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;
                if (!!this.scripts[name].attrs) {
                    for (var prop in this.scripts[name].attrs) {
                        script[prop] = this.scripts[name].attrs[prop];
                    }
                }

                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Error', error: error });
                const s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(script, s);
            }
        });
    }

    loadCss(name: string) {
        var head = document.getElementsByTagName('head')[0];

        var style = document.createElement('link');
        style.href = this.css[name].src;
        style.type = 'text/css';
        style.rel = 'stylesheet';
        head.append(style);
    }
}
