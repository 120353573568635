import { HttpClient, HttpContext, HttpEvent, HttpHandler, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

export interface HttpCacheValue<T> {
    value: T;
    created: number;
    maxage: number;
}

@Injectable()
export abstract class HttpCache {
    abstract get<T>(key: string): T | undefined;
    abstract set<T>(key: string, value: T, options: { maxage: number }): void;
    abstract remove(key: string): void;
}

@Injectable()
export class HttpSessionStorageCache implements HttpCache {
    private _prefix = "httpcache_";

    get<T>(key: string): T | undefined {
        const json = sessionStorage.getItem(this._prefix + key);
        if (!!json) {
            const cached = <HttpCacheValue<T>>JSON.parse(json);
            var expires = DateTime.fromMillis(cached.created).plus({seconds: cached.maxage});
            if (DateTime.utc() <= expires) {
                return cached.value;
            }
            else {
                sessionStorage.removeItem(this._prefix + key);
            }
        }
        return undefined;
    }

    remove(key: string) {
        sessionStorage.removeItem(this._prefix + key);
    }

    set<T>(key: string, value: T, options: { maxage: number}) {
        const data = <HttpCacheValue<T>>{ value, created: Date.now(), maxage: options.maxage };
        const json = JSON.stringify(data);
        sessionStorage.setItem(this._prefix + key, json);
    }
}

@Injectable()
export class HttpClientCached extends HttpClient {

    constructor(handler: HttpHandler, private cache: HttpCache) {
        super(handler)
    }

    getWithCache<T>(url: string, cache: { maxage: number, force?: boolean }, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        }) : Observable<T>
    {
        var key = url;
        if (!!options?.params) {
            key += "_" + options.params.toString();
        }
        key = btoa(key);

        if (!!cache.force) {
            this.cache.remove(key);
        }

        const cached = this.cache.get<T>(key);
        if (!!cached) {
            return of(cached);
        }
        
        return <Observable<T>>this.request<T>('GET', url, options as any).pipe(
            tap(response => {
                this.cache.set(key, response, { maxage: cache.maxage })
            })
        );
    }
}