import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
  host: { class: "dialogComponent" }
})
export class DialogMessageComponent implements OnInit {
  message: string;
  okTitle: string;
  okColor = "primary";
  cancelColor = "warn";

  constructor(private dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.okTitle = data.okTitle || "Ok";
  }

  ngOnInit() {
  }

  close(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }
}
