import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from 'luxon';
import { combineLatest } from 'rxjs';
import { FacebookService } from './facebook.service';
import { GoogleService } from './google.service';
import { UserService } from './user.service';

//-------------------- Locale ------------------------
@Injectable({
    providedIn: 'root'
})
export class ApplicationInitService {
    constructor(
        private http: HttpClient,
        private facebookService: FacebookService,
        private googleService: GoogleService,
        private userService: UserService
    ) { }

    private registerLocale(localeId: string) {
        return new Promise<void>((resolve, reject) => {
            console.log("Registering locale", localeId);

            //Set luxon locale
            Settings.defaultLocale = localeId;

            if (localeId === "en-US" || localeId == "en") {
                resolve();
                return;
            }

            /*Comment below is webpack magic string. It's a regex whitelist
              for which locales to import. If you remove it ALL locales will be included
              in the dist when building using aot. That's over a thousand files.
            */
            localeId = localeId.replace('sv-SE', 'sv');
            import(
                /* webpackInclude: /(sv)\.js$/ */
                `@angular/common/locales/${localeId}.js`
            )
                .then(module => {
                    registerLocaleData(module.default);
                    resolve();
                }, reject);
        });
    }

    private registerServiceWorker() {
        return new Promise<void>(resolve => {
            if ('serviceWorker' in navigator) {
                console.log('CLIENT: service worker registration in progress.');
                navigator.serviceWorker.register('/service-worker.js').then(function () {
                    console.log('CLIENT: service worker registration complete.');
                    resolve();
                }, function () {
                    console.log('CLIENT: service worker registration failure.');
                    resolve();
                });
            } else {
                console.log('CLIENT: service worker is not supported.');
                resolve();
            }
        });
    }

    init(localeId: string) {
        const _self = this;
        let googleLoaded = false;
        return new Promise<void>(resolveApplication => {
            this.registerLocale(localeId)
                .then(() => {
                    return this.registerServiceWorker();
                })
                .then(() => {
                    //Load facebook api
                    return this.facebookService.init();
                })
                .then(() => {
                    //Load google api
                    return this.googleService.init();
                })
                .then(() => {
                    googleLoaded = true;
                    this.http.get('/auth/isauthorized').subscribe(
                        () => {
                            this.userService.refreshCurrentUser();
                            resolveApplication();
                        },
                        error => {
                            //Login with social media if unauthorized
                            if (error.status === 401 || error.status === 403) {
                                //Try to login with facebook and google
                                const fbLogin = this.facebookService.login(false);

                                //Wait for both
                                fbLogin.then(responses => {
                                    //Check if success for any
                                      if (responses.alreadyConnected && responses.success) {
                                          _self.userService.refreshCurrentUser();
                                      }
                                    resolveApplication();
                                }, err => {
                                    resolveApplication();
                                });
                            }
                            else {
                                resolveApplication();
                            }
                        });
                })
                .catch(() => {
                    //If we failed on facebook init, make sure google is still initiated
                    if (!googleLoaded) {
                        this.googleService.init().finally(() => resolveApplication());
                    }
                    else {
                        resolveApplication();
                    }
                });
        });
    }
}