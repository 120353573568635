//Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, LOCALE_ID, Injectable, Inject } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Required
import { NgStackFormsModule } from '@ng-stack/forms';
import { CoalescingComponentFactoryResolver } from './core/services/coalescing-component-factory-resolver.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientCached, HttpCache, HttpSessionStorageCache } from './core/services/httpclientcached.service';

//Providers
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LuxonDateAdapter, LUXON_DATE_FORMATS } from './core/material/luxon-date-adapter.model';

//App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.errorhandler';
import { ApplicationInitService } from './core/services/application-init.service';

//Interceptors
import { BaseInterceptor } from './core/interceptors/base.interceptor';
import { DateInterceptor } from './core/interceptors/date.interceptor';

//Components
import { RootComponent } from './root/root.component';
import { Error500Component } from './errors/error500/error500.component';
import { Error403Component } from './errors/error403/error403.component';
import { Error404Component } from './errors/error404/error404.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

//Support
import { FeedbackModule } from './feedback/feedback.module';

@Injectable()
export class LocaleHolder {
  constructor(@Inject(MAT_DATE_LOCALE) public matDateLocale: string) {}
}

@NgModule({
  declarations: [
    AppComponent,
    Error500Component,
    RootComponent,
    Error403Component,
    Error404Component
  ],
  imports: [ 
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgStackFormsModule,
    MatDatepickerModule,
    MatDialogModule
  ],
  providers: [
    LocaleHolder,
    CoalescingComponentFactoryResolver,
    ApplicationInitService,
    HttpClientCached,
    {
      provide: APP_INITIALIZER,
      useFactory: init_application,
      deps: [LOCALE_ID, ApplicationInitService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, 
      useValue: {
        backdropClass: 'app-dialog-backdrop',
        closeOnNavigation: true,
        panelClass: 'app-dialog-panel'
      }
    },    
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
        floatLabel: 'auto'
      }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
      useValue: {
        verticalPosition: 'bottom',
        panelClass: 'snackbar-bottom'
      }
    },    
    {
      provide: DateAdapter, 
      useFactory: dateAdapterFactory,
      deps: [LocaleHolder]
    },
    {
      provide: MAT_DATE_FORMATS, 
      useValue: LUXON_DATE_FORMATS
    },    
    {
      provide: HttpCache,
      useClass: HttpSessionStorageCache
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(coalescingResolver: CoalescingComponentFactoryResolver) {
    coalescingResolver.init();
  }
}

export function dateAdapterFactory(localeHolder: LocaleHolder) {
  return new LuxonDateAdapter(localeHolder.matDateLocale);
}

export function init_application(localeId: string, applicationInitService: ApplicationInitService) {
  return () => applicationInitService.init(localeId);
}