import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-facebook-login-dialog',
  templateUrl: './facebook-login-dialog.component.html',
  styleUrls: ['./facebook-login-dialog.component.scss']
})
export class FacebookLoginDialogComponent implements OnInit {
  scope: string;
  dataExpired = false;

  constructor(private dialogRef: MatDialogRef<FacebookLoginDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.scope = data.scope;

    if (!!data.fb.authResponse && !!data.fb.authResponse.data_access_expiration_time) {
      var dataExpires = DateTime.fromSeconds(data.fb.authResponse.data_access_expiration_time);
      if (dataExpires < DateTime.local()) {
        this.dataExpired = true;
      }
    }
  }

  ngOnInit(): void {
  }

  connect() {
    const opts = <fb.LoginOptions>{ scope: this.scope };
    if (this.dataExpired) {
      opts.auth_type = 'reauthorize';
    }

    FB.login(res => {
      if (res.status === 'connected') {
        this.dialogRef.close({ code: res.authResponse.signedRequest });
      }
      else {
        this.dialogRef.close();
      }
    }, opts);
  }
}
