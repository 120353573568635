import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error500Component } from './errors/error500/error500.component';
import { RootComponent } from './root/root.component';
import { Error403Component } from './errors/error403/error403.component';
import { RedirectComponent } from './core/guards/redirect.guard';
import { Error404Component } from './errors/error404/error404.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RootComponent
  },
  {
    path: 'error/500',
    component: Error500Component
  },
  {
    path: 'error/403',
    component: Error403Component
  },  
  {
    path: 'error/404',
    component: Error404Component
  },    
  {
    path: 'redirect',
    component: RedirectComponent,
    data: {
      fromQuery: 'url',
      appendFromQuery: ['paymentid', 'paymentId']
    }
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    loadChildren: () => import('./pub/pub.module').then(m => m.PubModule)
  },
  {
    path: '**',
    redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
