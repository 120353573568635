import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleLoginRequest } from 'src/app/pub/_shared/models';
import { environment } from '../../../environments/environment';
import { GoogleSignInRequest, GoogleSignInResponse } from '../models';
import { ConnectResponse } from '../models/responses/connect-response.model';
import { GoogleCategory } from '../models/responses/google-category.model';
import { ScriptService } from './script.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleService {
    public isLoaded = false;
    public onReady = new BehaviorSubject<boolean>(false);

    constructor(private scriptService: ScriptService, private http: HttpClient) {
    }

    public init() : Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isLoaded) {
                this.onReady.next(true);
                resolve();
                return;
            }

            let timeout = null;
            const _self = this;

            // @ts-ignore
            window.onGoogleLibraryLoad = () => {
              _self.isLoaded = true;
              _self.onReady.next(true);
              resolve();
            };
          
            this.scriptService.loadScript('googleaccounts', ['google.accounts']).then(data => {
              if (!data.loaded) {
                reject();
              }

              //Google gets five seconds to complete, otherwise reject
              timeout = setTimeout(() => reject(), 5000);
            });
  
        });
    }

    searchMyBusinessCategories(query: string): Observable<GoogleCategory[]> {
        const params = new HttpParams()
        .set('query', query);
        return this.http.get<GoogleCategory[]>('/util/google/categories', { params })
    }

    deauthorize(): Promise<void> {
        return new Promise((resolve) => {
            const auth2 = gapi.auth2.getAuthInstance();
            auth2.signIn().then(() => {
                auth2.currentUser.get().disconnect();
                resolve();              
                this.logout();
            });        
        });
    }

    logout() {
        return new Promise((resolve) => {
            const auth2 = gapi.auth2.getAuthInstance();        
            auth2.signOut().then(resolve);
        });
    }

    login(prompt = true): Promise<GoogleSignInResponse> {    
        return new Promise((resolve, reject) => {

          this.connect().then(resolve, reject);
          return;

            const auth2 = gapi.auth2.getAuthInstance();
            var response = new GoogleSignInResponse();

            auth2.then(auth => {
                if (auth.isSignedIn.get()) {
                    const user = auth2.currentUser.get();
                    const scopes = user.getGrantedScopes().split(' ');
        
                    //If user hasn't granted email, prompt
                    if (!scopes.some(x => x === 'email')) {
                        if (prompt) {
                            this.connect().then(resolve, reject);
                        }
                        else {
                            response.success = false;
                            reject();
                        }
                        return response;
                    }

                    response.success = true;
                    response.alreadyConnected = true;
                    this.loginInternal().then(success => {
                        response.success = success;
                        resolve(response);
                    }, reject);
                }
                else if (!prompt) {
                    resolve(response);
                }
                else {
                    this.connect().then(resolve, reject);
                }
            });
        });      
    }    

    connect(): Promise<GoogleSignInResponse> {
        return new Promise<GoogleSignInResponse>((resolve, reject) => {
            let conn = new GoogleSignInResponse();
            // @ts-ignore
            var googleAccount = google.accounts.oauth2.initTokenClient({
                client_id:  environment.google.clientId,
                callback: (response) => {
                  console.log(response);
                  resolve(conn);
                },
                scope: 'profile email'
              }
            )
            googleAccount.requestAccessToken();

            /*
            const auth2 = gapi.auth2.getAuthInstance();
            const _self = this;

            auth2.then(auth => {
                auth.signIn({
                    prompt: 'select_account',
                    scope: 'profile email'
                })
                .then(user => {
                    conn.grantedScopes = user.getGrantedScopes().split(' ');

                    if (conn.grantedScopes.indexOf('email') == -1) {
                        //Need email access
                        user.disconnect();
                        conn.success = false;
                        reject(conn);
                        return;
                    }

                    let authRes = user.getAuthResponse();
                    let profile = user.getBasicProfile();
                    var request = new GoogleSignInRequest();
                    request.name = profile.getName();
                    request.email = profile.getEmail();
                    request.id = profile.getId();
                    request.imageUrl = profile.getImageUrl();
                    request.accessToken = authRes.id_token;

                    _self.http.post<ConnectResponse>('/auth/google/connect', request).subscribe(response => {
                        conn.success = true;
                        conn.exists = response.exists;
                        conn.userId = !!response.user ? response.user.id : null;
                        conn.alreadyConnected = response.exists && !! response.user;
                        resolve(conn);
                    }, 
                    () => {
                        user.disconnect();
                        conn.success = false;
                        reject(conn);
                    });
                })
                .catch(() => {
                    conn.success = false;
                    conn.grantedScopes = [];
                    reject(conn);
                })
            });
            */
        });
    }        

    private loginInternal(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const auth2 = gapi.auth2.getAuthInstance();
            const user = auth2.currentUser.get();

            const profile = user.getBasicProfile();
            const auth = user.getAuthResponse();

            let credentials = new GoogleLoginRequest();
            credentials.id = profile.getId();
            credentials.email = profile.getEmail();
            credentials.accessToken = auth.id_token;

            this.http.post('/auth/google/login', credentials).subscribe(
                () => { resolve(true); }
                ,reject
            );                                
        });
    }
}
