import { environment as defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  production: true,
  beta: false,
  backend: {
    url: 'https://app-backend.basetool.com'
  },
  eventsapp: {
    url: 'https://events.basetool.com/',
    backend: 'https://events-backend.basetool.com/'
  },
  media: {
    contentEndpoint: 'https://content01.basetool.se',
    publicEndpoint: 'https://media.basetool.se'
  },
  facebook: {
    appId: '160341074531472',
    apiVersion: 'v9.0'
  },
  google: {
    clientId: '669776535801-n0dt8kbjn3vn5ho2l9lc7534s1pb68au.apps.googleusercontent.com',
    key: 'AIzaSyD6d-1p3bt9n0s9LLPzP6hPAe12WIFdH_c',
    reCaptcha: '6LehR7IZAAAAAIOy1QWOp4Ppq5tuYZZFu-gOBN7T',
    reCaptchav2: '6Lf3hLIZAAAAAPs_MFUoPVRCVVZ16HubOxd7hW1x',
    analyticsId: 'G-20S3NKDH24'
  },
  netseasy: {
    checkoutKey: '151699e0a5794a7a994a585e9c149c1b',
    environment: 'live'
  },
  notifications: {
    applicationServerPublicKey: 'BPHGRQwgQl7TMbTDo0O73yKaeGR93_8vWylt0DcGkEJJaRCVoLPPpO8ineKPeTtbxwiB2_3IJetTSzTOSilkOUE'
  }
};
