import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { AnalyticsService } from './core/services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'basetool5';
  bodyClass = document.body.className.replace('init', '');
  private _timer = null;
  private _online = true;
  private _onlineSub = new BehaviorSubject<boolean>(this._online);
  private _offlineElement: HTMLDivElement;

  constructor(private router: Router, private renderer: Renderer2, private route: ActivatedRoute, private analytics: AnalyticsService, private http: HttpClient, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this._offlineElement = document.createElement('div');
    this._offlineElement.id = "offlineNotification";
    
    this.matIconRegistry.addSvgIcon(
      "website",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/website.svg")
    );
  }

  ngOnInit() {
    //Handle data on route change
    this.router.events
      .pipe(
        untilDestroyed(this),
        tap(event => {
          if (event instanceof ResolveEnd) {
            this.renderer.removeClass(document.body, 'init');
          }
        }),
        filter(event => event instanceof NavigationEnd),
        tap(event => {
          //Google analytics set page          
          this.analytics.setPage((<NavigationEnd>event).urlAfterRedirects);
        }),
        map(() => this.route),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data && data.body) {
          this.renderer.addClass(document.body, data.body);
        } else {
          this.renderer.setAttribute(document.body, 'class', this.bodyClass);
        }
      });

    //Handle online/offline events
    this._timer = setInterval(() => {
      this.http.head('/util/ping').pipe(timeout(5000)).subscribe(
        () => this._onlineSub.next(true),
        () => this._onlineSub.next(false)
      );
    }, 10000);
    
    this._onlineSub.pipe(untilDestroyed(this)).subscribe(online => {
      if (!online && this._online) {
        this._offlineElement.innerText = $localize`Det verkar som att du inte är online. Kontrollera wifi/mobil-signal.`;
        this._offlineElement.className = "";
        document.body.appendChild(this._offlineElement);
      }
      else if (!this._online && online) {
        this._offlineElement.innerText = $localize`Du är online igen!`;
        this._offlineElement.className = "online";
        setTimeout(() => {
          document.body.removeChild(this._offlineElement);
        }, 3000);
      }
      this._online = online;
    });
  }

  ngOnDestroy() {
    clearInterval(this._timer);
  }
}