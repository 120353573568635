import { ComponentType } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import {
    HttpErrorResponse, HttpEvent, HttpHandler,
    HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, retryWhen, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FacebookLoginDialogComponent } from '../components/facebook-login-dialog/facebook-login-dialog.component';
import { GoogleLoginDialogComponent } from '../components/google-login-dialog/google-login-dialog.component';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {

    constructor(private location: Location, private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf("assets") === -1) {
            if (request.method.toLowerCase() === "post" && request.body && request.body instanceof FormData) {
                request = request.clone({
                    url: environment.backend.url + request.url,
                    withCredentials: true
                });
            }
            else {
                request = request.clone({
                    url: environment.backend.url + request.url,
                    setHeaders: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
            }
        }

        return next.handle(request).pipe(
            retryWhen(errors => {
                return errors.pipe(
                    switchMap(err => { 
                        if (err instanceof HttpErrorResponse && err.status === 401 && !!err.error) {
                            if (err.error.source === 'facebook') {
                                return new Observable<any>(observer => {
                                    FB.getLoginStatus(res => {
                                        observer.next({ error: err.error, fb: res });
                                        observer.complete();
                                    });
                                });
                            }                            
                            else if (err.error.source === 'google') {
                                return from([{ error: err.error }]);
                            }
                        }
                        return throwError(err);
                    }),
                    switchMap(res => {
                        let dialog: ComponentType<any>;                     
                        if (res.error.source === 'facebook') {
                            if (res.fb.status === 'connected') {
                                if (res.error.code === 190 || res.error.code === 104 || (res.error.code === 102 && (!res.error.subCode || res.error.subCode === 463 || res.error.subCode === 467))) {
                                    return from([{ error: res.error, code: res.fb.authResponse.signedRequest }]);
                                }
                            }
                            dialog = FacebookLoginDialogComponent;
                        }
                        else {
                            dialog = GoogleLoginDialogComponent;
                        }

                        if (!!dialog) {
                            const dialogService = this.injector.get(DialogService);
                            return new Observable<any>(observer => {
                                dialogService.open(dialog, false, true, {data:{ fb: res.fb, scope: res.error.scope  }}, true)
                                .then(d => {
                                    d.afterClosed().subscribe(d => {
                                        observer.next({ error: res.error, code: d.code });
                                        observer.complete()
                                    });
                                });
                            });
                        }

                        return throwError(res.error);
                    }),
                    switchMap((response: any) => { 
                        if (!response.code) {
                            return throwError({ ...response.error, userDenied: true });
                        }
                                                
                        const userService = this.injector.get(UserService);
                        if (response.error.source === 'facebook') {
                            return userService.refreshFacebookAccessToken(response.code);
                        }
                        else {
                            return userService.refreshGoogleAccessToken(response.code);
                        }
                    })
                )
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    //The error is handled in /app.errorhandler.ts
                    sessionStorage.setItem('authReturnUrl', this.location.path(true));
                }
                return throwError(err);
            })
        );
    }
}