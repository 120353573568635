import { BreakpointObserver } from "@angular/cdk/layout";
import { ComponentType } from "@angular/cdk/portal";
import { Injectable, OnDestroy, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Observable, Subject } from "rxjs";
import { first } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { DialogConfirmComponent } from "../components/dialog-confirm/dialog-confirm.component";
import { DialogMessageComponent } from "../components/dialog-message/dialog-message.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService implements OnDestroy {
    private confirmSubject = new Subject<boolean>();
    private currentDialog: MatDialogRef<any>;
    private closeDialogOnNavRef: any;

    constructor(private dialog: MatDialog, private breakpointObserver: BreakpointObserver) {
        this.closeDialogOnNavRef = this.closeDialogOnNav.bind(this);
        window.addEventListener('popstate', this.closeDialogOnNavRef, { passive: true });
    }    

    ngOnDestroy() {
        window.removeEventListener('popstate', this.closeDialogOnNavRef);
    }

    open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, fullscreen = false, lightBackDrop = false, cfg?: MatDialogConfig<D>, keepExisting?: boolean): Promise<MatDialogRef<T, R>> {
        return new Promise((resolve) => {
            let options = cfg || {};
            options.hasBackdrop = true;
            options.panelClass = "app-dialog-panel-default";
            options.closeOnNavigation = false;
            
            if (fullscreen) {
                options.panelClass = "app-dialog-panel-fullscreen";
                if (this.breakpointObserver.isMatched(environment.breakpoints.small)) {
                    options.hasBackdrop = false;
                    options.maxWidth = '100vw';
                    options.maxHeight = '100vh';
                }
                else {
                    //Width,Height is set in styles/dialogs/medium
                    options.maxWidth = '80vw';
                    options.maxHeight = '80vh';
                }
            }
            if (lightBackDrop) {
                options.backdropClass = "app-dialog-backdrop-light";
            }
     
            if (this.dialog.openDialogs.length === 0 || keepExisting) {
                resolve(this.openDialog(componentOrTemplateRef, fullscreen, options));
            }
            else {
                window.addEventListener('popstate', () => {
                    //Timeout is needed to wait for previous dialog to completely close, otherwise we get problems
                    setTimeout(() =>  { 
                        resolve(this.openDialog(componentOrTemplateRef, fullscreen, options)); }, 200);
                }, { once: true });
                this.currentDialog.close();
            }
        });
    }

    closeAll(skipNavigation?: boolean) {
        this.dialog.openDialogs.forEach(d => {
            var result = null;
            if (skipNavigation) {
                result = { dialog: { navigate: false }};
            }
            d.close(result);
        });
    }

    confirm(message: string, okTitle?: string, invert: boolean = false, cancelTitle?: string) : Observable<boolean> {
        const ref = this.dialog.open(DialogConfirmComponent, { 
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true, 
            panelClass: "app-dialog-panel-default", 
            data: { message, okTitle, invert, cancelTitle }
        });

        ref.updateSize("80%");

        ref.afterClosed().subscribe(confirmed => {
            if (typeof confirmed === undefined) {
                confirmed = false;
            }
            this.confirmSubject.next(confirmed);
        });

        return this.confirmSubject.asObservable().pipe(first());
    }

    message(message: string, okTitle?: string) : Observable<boolean> {
        const ref = this.dialog.open(DialogMessageComponent, { 
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true, 
            panelClass: "app-dialog-panel-default", 
            data: { message: message, okTitle: okTitle } 
        });

        ref.updateSize("80%");

        ref.afterClosed().subscribe(confirmed => {
            this.confirmSubject.next(true);
        });

        return this.confirmSubject.asObservable().pipe(first());
    }

    private openDialog<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, fullscreen: boolean, options: MatDialogConfig<D>): MatDialogRef<T, R> {
        const dialog = this.dialog.open(componentOrTemplateRef, options);

        history.pushState({}, document.title, this.getPathQuery() + "#dialog" + Math.trunc((Math.random() * 1000)));

        if (fullscreen) {
            dialog.updateSize("100%", "100%");
        }
        else {
            dialog.updateSize("80%");
        }

        dialog.afterClosed().subscribe(data => {
            if (data && data.dialog && data.dialog.hasOwnProperty('navigate') && data.dialog.navigate === false) {
                this.currentDialog = null;
                return;
            }
            history.back();
        });

        this.currentDialog = dialog;
        return dialog;
    }

    private closeDialogOnNav() {
        if (!!this.currentDialog && this.currentDialog.getState() === MatDialogState.OPEN) {
            this.currentDialog.close({ dialog: { navigate: false } });
        }
        const dialogs = this.dialog.openDialogs.filter(x => !!this.currentDialog && x.id !== this.currentDialog.id);
        this.currentDialog = dialogs.length > 0 ? dialogs[0] : null;
    }

    private getPathQuery(): string {
        return location.pathname + (location.search || "");
    }
}