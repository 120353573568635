import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    template: 'Om du inte omdirigeras automatiskt så kan du istället <a href="{{url}}">klicka här</a>.',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent {
    url: string;
    
    constructor(route: ActivatedRoute) {
        const fromQuery = route.snapshot.data['fromQuery'];
        let url = "";

        if (!!fromQuery)
        {
            const uri = new URL(route.snapshot.queryParamMap.get(fromQuery));
            const appendFromQuery = route.snapshot.data['appendFromQuery'];
            if (!!appendFromQuery) {
                url = uri.protocol + '//' + uri.host + uri.pathname;
                url += !!uri.search ? (uri.search + "&") : "?";
                for (let i = 0; i < appendFromQuery.length; i++)
                {
                    if (route.snapshot.queryParamMap.has(appendFromQuery[i])) {
                        const value = route.snapshot.queryParamMap.get(appendFromQuery[i]); 
                        url += appendFromQuery[i] + "=" + (value || '');
                    }
                }

                if (!!uri.hash)
                    url += uri.hash;
            }
            else {
                url = uri.toString();
            }
        }
        else
        {
            url = route.snapshot.data['externalUrl'];
        
            const params = url.match(/{[^}]*}/g);
            if (params.length > 0) {
                for (var i = 0; i < params.length; i++) {
                    if (params[i] === '{BACKEND}') {
                        url = url.replace('{BACKEND}', environment.backend.url);
                    }
                    else {
                        url = url.replace(params[i], route.snapshot.paramMap.get(params[i].replace(/{|}/g, '')));
                    }
                }
            }

            if (!!window.location.search)
                url += window.location.search;
            if (!!window.location.hash)
                url += window.location.hash;
        }

        this.url = url;
        window.location.href = url;
    }
}
