import { HttpClient } from '@angular/common/http';
import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FacebookLoginRequest } from 'src/app/pub/_shared/models';
import { environment } from '../../../environments/environment';
import { FacebookConnectRequest, FacebookConnectResponse } from '../models';
import { ConnectResponse } from '../models/responses/connect-response.model';
import { ScriptService } from './script.service';

@Injectable({
    providedIn: 'root'
})
export class FacebookService {
    public isLoaded: boolean = false;
    static facebookLocales = { en: "en_US", sv: "sv_SE" };

    constructor(private scriptService: ScriptService, @Inject(LOCALE_ID) private locale: string, private cookies: CookieService, private http: HttpClient) {
    }

    public init(): Promise<void> {
        return new Promise((resolve, reject) => {            
            if (this.isLoaded) {
                resolve();
                return;
            }

            let timeout = null;
            window['fbAsyncInit'] = function () {                
                //We need status=true to make sure login status is available from start
                FB.init({
                    appId: environment.facebook.appId,
                    xfbml: false,
                    version: environment.facebook.apiVersion,
                    status: true
                });

                if (!!timeout) {
                    clearTimeout(timeout);
                }
                
                this.isLoaded = true;
                resolve();                
            }

            var locale = FacebookService.facebookLocales[this.locale];
            this.scriptService.loadScript('facebookapi', [locale || "en_US"]).then(data => {
                if (!data.loaded) {
                    reject();
                }
               
                //Facebook gets five seconds to complete, otherwise reject
                timeout = setTimeout(() => reject(), 5000);
             });
        });
    }

    public hasPermissions(scope: string, roundtrip = false): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            FB.getLoginStatus(res => {
                if (res.status === 'connected') {
                    if (!res.authResponse.grantedScopes) {
                        //Response did not return granted scopes for some reason, check api
                        FB.api('me/permissions', (permissions: { data: any[] }) => {
                            const granted = permissions.data.filter(x => x.status === 'granted').map(x => x.permission.toLowerCase());
                            resolve(!scope.toLowerCase().split(',').some(x => granted.indexOf(x) === -1));
                        });
                        return;
                    }
                    else {                        
                        const granted = res.authResponse.grantedScopes.toLowerCase().split(',');
                        resolve(!scope.toLowerCase().split(',').some(x => granted.indexOf(x) === -1));
                        return;
                    }
                }
                resolve(false);
            }, roundtrip);
        });
    }

    public requestPermissions(scope: string): Promise<fb.StatusResponse> {
        return new Promise((resolve, reject) => {            
            FB.login(response => {
                if (!response.authResponse.grantedScopes) {
                    reject();
                }

                var granted = response.authResponse.grantedScopes.split(',');
                if (scope.split(',').some(x => granted.indexOf(x) === -1)) {
                    reject();
                }
                resolve(response);
            }, {
                scope,
                return_scopes: true
            });
        });
    }

    public deauthorize(): Promise<void> {
        return new Promise((resolve, reject) => {
            FB.login(() => {
                FB.api('/me/permissions', 'delete', null, (res: any) => {
                    if (res.success) {
                        resolve();
                        this.logout();
                    }
                    else {
                        reject();
                    }
                });
            });        
        });
    }

    public login(prompt = true): Promise<FacebookConnectResponse> {
        this.deleteFacebookLogoutCookie();
        return new Promise((resolve, reject) => {
            var response = new FacebookConnectResponse();
            FB.getLoginStatus(res => {
                if (res.status === "connected") {
                    response.success = true;
                    response.alreadyConnected = true;
                    this.loginInternal(res).then(success => {
                        response.success = success;
                        resolve(response);
                    }, reject);
                }
                else if (!prompt) {
                    resolve(response);
                }
                else {
                    this.connect().then(resolve, reject);
                }
            }, false); //Force needs to be false, otherwise an async request is made which triggers popup-blockers when this is called from button click
        });      
    }

    private connect(): Promise<FacebookConnectResponse> {
        return new Promise<FacebookConnectResponse>((resolve, reject) => {
            let conn = new FacebookConnectResponse();
            let _self = this;

            FB.login(res => {
                if (res.authResponse) {
                    conn.grantedScopes = res.authResponse.grantedScopes.split(",");

                    if (conn.grantedScopes.indexOf("email") === -1) {
                        //No email access granted, revoke access
                        FB.api('/me/permissions', 'delete', null, function () {
                            conn.success = false;
                            reject(conn);
                        });
                        return;
                    }

                    FB.api<any, any>('/me', { fields: 'id,email,first_name,last_name,picture' }, me => {
                        var model = new FacebookConnectRequest();
                        model.accessToken = res.authResponse.accessToken;
                        model.id = me.id;
                        model.email = me.email;
                        model.name = me.first_name + " " + me.last_name;
                        model.imageUrl = me.picture.data.url;

                        _self.http.post<ConnectResponse>('/auth/facebook/connect', model).subscribe(response => {
                            conn.success = true;
                            conn.exists = response.exists;
                            conn.userId = !!response.user ? response.user.id : null;
                            conn.alreadyConnected = response.exists && !!response.user;
                            resolve(conn);
                        },
                        error => {
                            //Failed to create local user, revoke access
                            FB.api('/me/permissions', 'delete', null, function () {
                                conn.success = false;
                                reject(conn);
                            });
                        });
                    });

                    //Login status is cached, get it here
                    FB.getLoginStatus(() => {}, true);
                }
                else {
                    conn.success = false;
                    conn.grantedScopes = [];
                    reject(conn);
                }
            }, { scope: 'email', return_scopes: true });
        });
    }    

    private loginInternal(status: fb.StatusResponse): Promise<boolean> {
        const _self = this;
        return new Promise((resolve, reject) => {
            FB.api<any, any>('/me', { fields: 'id,email' }, me => {
                //If user only connected a business, we might not have access to email
                if (!me.email) {
                    resolve(false);
                    return;
                }

                var credentials = new FacebookLoginRequest();
                credentials.id = me.id;
                credentials.email = me.email;
                credentials.accessToken = status.authResponse.accessToken;
                _self.http.post('/auth/facebook/login', credentials).subscribe(() => {
                    resolve(true);
                }, reject);
            });
        });
    }

    public logout(): Promise<void> {
        return new Promise((resolve) => {
            FB.getLoginStatus(() => {
                FB.logout(res => {
                    this.deleteFacebookLogoutCookie();
                    resolve();
                }); 
            });
        });
    }

    private deleteFacebookLogoutCookie() {
        //This cookie should be removed on logout but isn't always https://stackoverflow.com/questions/22889646/fb-getloginstatus-returns-status-unknown
        this.cookies.delete('fblo_' + environment.facebook.appId, '/', document.location.host.split(':')[0]);
    }
}
