import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models';
import { IUserEmailForm } from '../../admin/user/edit/email-form.model';
import { IUserPasswordForm } from '../../admin/user/edit/password-form.model';
import { IEditUserForm } from '../../admin/user/edit/edit-form.model';
import { FacebookService } from './facebook.service';
import { GoogleService } from './google.service';
import { environment } from '../../../environments/environment';
import { NotificationsResponse } from '../models/responses/notificationsResponse.model';
import { CurrentUser } from '../models/responses/currentUser.model';
import { UserContentResponse } from '../models/responses/userContentResponse.model';
import { UserChannelAreaResponse } from '../models/responses/UserChannelAreaResponse.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<CurrentUser>(null);
  currentUser = this.currentUserSubject.asObservable();

  constructor(private http: HttpClient, private facebook: FacebookService, private google: GoogleService) {}

  private loadUser() {
    this.http.get<CurrentUser>('/user/me').subscribe(u => {
      this.currentUserSubject.next(u);
    },
    () => {});
  }

  refreshCurrentUser() {
    this.loadUser();
  }

  edit(): Observable<User> {
    return this.http.get<User>('/user/edit');
  }

  save(form: IEditUserForm) : Observable<void> {
    return this.http.post<void>('/user/edit', form);
  }

  changeEmail(form: IUserEmailForm) : Observable<void> {
    return this.http.post<void>('/user/email', form);
  }

  changePassword(form: IUserPasswordForm) : Observable<void> {
    return this.http.post<void>('/user/password', form);
  }

  verifyEmail(data: string): Observable<void> {
    return this.http.post<void>('/user/verifyemail', { data });
  }

  sendVerificationEmail(): Observable<void> {
    return this.http.post<void>('/user/verifyemail/send', {});
  }  

  getContent() : Observable<UserContentResponse> {
    return this.http.get<UserContentResponse>('/user/me/content');
  }

  getChannelAreas(channelId: number): Observable<UserChannelAreaResponse> {
    return this.http.get<UserChannelAreaResponse>(`/user/me/channel/${channelId}/areas`);
  }

  refreshGoogleAccessToken(code: string): Observable<void> {
    return this.http.post<void>('/user/google/refreshToken', { code });
  }

  refreshFacebookAccessToken(signedRequest: string): Observable<void> {
    return this.http.post<void>('/user/facebook/refreshToken', { signedRequest });
  }

  deconnectGoogle(): Observable<void> {
    return this.http.get<void>('/user/google/deconnect');
  }

  deauthorize(): Observable<void> {
    return this.http.post<void>('/user/socialmedia/deauthorize', { self: true });
  }

  subscribePushNotifications(endpoint: string, p256dh: string, auth: string): Observable<void> {
    return this.http.post<void>('/notification/subscription', {
      endpoint,
      p256dh,
      auth
    });
  }

  unsubscribePushNotifications(endpoint: string): Observable<void> {
    var params = new HttpParams()
    .set("endpoint", endpoint);

    return this.http.delete<void>('/notification/subscription', { params: params });
  }

  getNotifications(): Observable<NotificationsResponse> {
    return this.http.get<NotificationsResponse>('/notification');
  }

  clearNotifications(): Observable<void> {
    return this.http.delete<void>('/notification');
  }

  removeNotification(guid: string): Observable<void> {
    return this.http.delete<void>('/notification/' + guid);
  }

  logout(): Promise<void> {
    return new Promise(resolve => {
      this.http.post('/auth/logout', {}).subscribe(() => {
        //Log out from social media
        try {
          this.facebook.logout();
        }
        catch (e) {
          console.log("Unable to logout social media", e);
        }

        //Remove gtag user id
        gtag('config', environment.google.analyticsId, { user_id: null });

        resolve();
      });
    });
  }
}