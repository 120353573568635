import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClaimingType, HubSubscription } from '../models';
import { ScriptService } from './script.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(scripts: ScriptService, user: UserService) {
    scripts.loadScript('gtag', [ environment.google.analyticsId ]).then(() => {
      gtag('config', environment.google.analyticsId, { 
        anonymize_ip: true, 
        send_page_view: false
      });

      user.currentUser.subscribe(u => {
        if (!!u && !!u.id) {
          gtag('config', environment.google.analyticsId, {
            user_id: 'user-' + u.id
          });
        }
      });
    });
  }

  setPage(path: string, sendPageView = true) {
    gtag('config', environment.google.analyticsId, {
      page_path: path,
      send_page_view: sendPageView
    });
  }

  trackHubClaim(action: 'send'|'verify'|'success', type: ClaimingType) : Promise<void> {
    return this.sendEvent('claim', action, type.toString().toLowerCase());
  }

  trackSubscription(
    action: 'created_free'|'created_card'|'created_invoice',
    subscription: HubSubscription,
    paymentInterval: number): Promise<void> {
      return this.sendEvent('subscription', action, subscription.toString().toLowerCase(), paymentInterval);
  }

  trackTranslation(action: 'automatic'|'publish'): Promise<void> {
    return this.sendEvent('translate', action);
  }

  trackGoogleConnect(action: 'connected'|'mapped_location'|'requested_code'|'success'|'disconnected'): Promise<void> {
   return this.sendEvent('googleconnect', action); 
  }

  trackFacebookConnect(): Promise<void> {
    return this.sendEvent('facebookconnect', 'connected');
  }
  
  trackSocialmediaPost(media: 'facebook'|'google'|'instagram') {
    return this.sendEvent('socialmediapost', 'post_' + media);
  }

  sendEvent(category: string, action: string, label: string = null, value: number = null): Promise<void> {
    return new Promise((resolve, reject) => {
      gtag('event', category + "-" + action, {
        event_category: category,
        event_label: label,
        value: value,
        event_callback: function() {
          resolve();
        }
      });
    });
  }
}
