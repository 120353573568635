export class GoogleSignInRequest {
    id: string;
    email: string;
    name: string;
    imageUrl: string;
    accessToken: string;
}

export class GoogleSignInResponse {
    success: boolean;
    alreadyConnected: boolean;
    grantedScopes: string[];
    userId: number;
    exists: boolean;
}